import { payloadPromotionResults, promotionParams } from "~/types/schema";
import { removeFieldEmptyValue } from "~/utils";

export const getListCategories = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/promotions/categories`,
    method: "get",
  });
};

export const getListPromotions = (payload: promotionParams) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/promotions`,
    method: "get",
    params: removeFieldEmptyValue(payload),
  });
};

export const getDetailPromotion = (slug: string) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/promotions/${slug}`,
    method: "get",
  });
};

export const checkEmailToday = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/email-draw-users/check`,
    method: "get",
  });
};

export const sendEmailToday = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/email-draw-users/request`,
    method: "post",
  });
};

export const optInPrize = (prizeId: number) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/prize-generations/user/opt-in/${prizeId}`,
    method: "get",
  });
};

export const getLastPromotionResult = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/promotions/last-result`,
    method: "get",
  });
};

export const getPromotionResults = (payload: payloadPromotionResults) => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/promotions/results`,
    method: "get",
    params: payload,
  });
};
